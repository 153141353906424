import { Link, PageProps } from 'gatsby'

import React from 'react'

const siteUrl = 'https://luckypaper.co'

interface Props extends PageProps<Queries.FormatTemplateQuery> {
  pageContext: {
    redirectTo: string
  }
}

export const Head: React.FC<Props> = (props) => {
  const { redirectTo } = props.pageContext

  const redirectURL = `${siteUrl}${redirectTo}`

  return (
    <>
      <title>Page Moved</title>
      <meta httpEquiv="refresh" content={`0; url=${redirectURL}`} />{' '}
    </>
  )
}

const RedirectPage: React.FC<Props> = (props) => {
  const { redirectTo } = props.pageContext

  const redirectURL = `${siteUrl}${redirectTo}`

  return (
    <div>
      Page moved to &ldquo;<Link to={redirectTo}>{redirectURL}</Link>&rdquo;
    </div>
  )
}

export default RedirectPage
